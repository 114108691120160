import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of deload week`}</em></p>
    <p>{`DB Split Jerk 12-12-12\\@60% 1RM`}</p>
    <p>{`Band Pull Aparts 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`30 Double Unders`}</p>
    <p>{`20 DB S2OH (30/20’s)`}</p>
    <p>{`10 Ring Rows (rx=chest under rings, body in plank position)`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday is our free workout so bring a friend! 8:00 & 9:00am
at The Ville, 10:00 & 11:00am at East.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      